import {Account} from 'views/account';
import {Profile} from 'views/account/profile';
import {Users} from 'views/account/users';
import {Experience} from 'views/account/experience';
import {ManageExperience} from 'views/account/manageExperince';
import {Password} from 'views/account/password';
import {PendingUpdateExperience} from 'views/account/pendingUpdateExperience';
import {Bookings} from 'views/account/booking';

const Routes = [
  {
    path: '/account',
    view: Account,
    layout: 'app',
    permission: 'provider',
    title: 'Your Account'
  },
  {
    path: '/account/profile',
    view: Profile,
    layout: 'app',
    permission: 'provider',
    title: 'Your Profile'
  },
  {
    path: '/account/password',
    view: Password,
    layout: 'app',
    permission: 'provider',
    title: 'Change Password'
  },
  {
    path: '/account/users',
    view: Users,
    layout: 'app',
    permission: 'administrator',
    title: 'Users'
  },
  {
    path: '/account/experience',
    view: Experience,
    layout: 'app',
    permission: 'provider',
    title: 'Experience'
  },
  {
    path: '/account/experience/updates-pending',
    view: PendingUpdateExperience,
    layout: 'app',
    permission: 'provider',
    title: 'Pending Experiences'
  },
  {
    path: '/account/bookings',
    view: Bookings,
    layout: 'app',
    permission: 'administrator',
    title: 'Booked Experiences'
  },
  {
    path: '/account/create-experience',
    view: ManageExperience,
    layout: 'app',
    permission: 'provider',
    title: 'Create Experience'
  },
  {
    path: '/account/edit-experience/:id',
    view: ManageExperience,
    layout: 'app',
    permission: 'provider',
    title: 'Edit Experience'
  }
]

export default Routes;