import { useState } from 'react';
import { Label, Error, Icon, ClassHelper } from 'components/lib';
import Style from './input.tailwind.js';
import {Tip} from '../tip/tip.js';

export function NumberInput(props){

  const [error, setError] = useState(props.errorMessage || 'Please enter a number');

  function validate(e){

    let value = e ? e.target.value : '';
    let valid = undefined;
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value)
      valid = true;

    // now test for a valid number
    if (isNaN(value)){

      valid = false;
      setError('Please enter a valid number');

    }

    if (props.min && value < props.min) {
      valid = false;
      setError(`Please enter at least ${props.min} characters`)
    }
    if (props.max && value > props.max) {
      valid = false;
      setError(`Please enter no more than ${props.max} characters`)
    }

    // update the parent form
    props.onChange?.(props.name, value, valid, props.idx);

  }

  // style
  const numberStyle = ClassHelper(Style, {

    textbox: true, 
    className: props.className, 
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,

  });

  return(
    <div className={ Style.input }>

      { props.label && 
        <Label
          text={ props.label }
          required={ props.required }
          for={ props.name }
        /> }
{ props.tip && 
        <Tip
          text={ props.tip }
          required={ props.required }
          for={ props.name }
        /> }
      <input
        type='number'
        id={ props.name }
        name={ props.name }
        className={ numberStyle }
        value={ props.value || props.value == 0 ? props.value : '' }
        min={ props.min }
        max={ props.max }
        disabled={ props.disabled }
        placeholder={ props.placeholder }
        onChange={ e => props.onChange?.(props.name, e.target.value, undefined, props.idx) }
        onBlur={ e => validate(e) }
      />

      { props.valid === false &&
        <Error message={ error }/> }

      { props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={ Style.successIcon }
          size={ 16 }
        />}

    </div>
  );
}
