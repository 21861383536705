/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props){

  return (
    <Animate type='slideup'>
      <footer className={ Style.footer }>
        <Row>
          <Content>
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}