/***
*
*   DASHBOARD
*   Template dashboard example demonstrating various components inside a view.
*
**********/

import React from 'react';
import { Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, useAPI } from 'components/lib';

export function Dashboard(props){

  return (
    <Animate type='pop'>

      <Message
        closable
        title='Welcome, manage your users and add experiences using the panel to the left.'
        text=''
        type='info'
      />
    </Animate>
  );
}
