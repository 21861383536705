
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {AccountNav, Animate, AuthContext, Button, Card, Select, Table, TextInput, TitleRow, useAPI, ViewContext} from 'components/lib';
import moment from 'moment';
import {Fragment, useCallback, useContext, useEffect, useState} from 'react';
import {Pagination} from 'components/pagination/pagination';

export function Experience(props) {
  const viewContext = useContext(ViewContext);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  const [loading, setLoading] = useState(false)
  const [experiences, setExperiences] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [filters, setFilters] = useState({
    searchText: '',
    page: 1,
    limit: 10,
    orderBy: 'title desc',
  });

  const sortOptions = [
    {label: "Title DESC", value: "title desc"},
    {label: "Title ASC", value: "title asc"},
    {label: "Date DESC", value: "date desc"},
    {label: "Date ASC", value: "date asc"},
    {label: "Status DESC", value: "status desc"},
    {label: "Status ASC", value: "status asc"},
    {label: "Provider Name DESC", value: `"user.name" desc`},
    {label: "Provider Name ASC", value: `"user.name" asc`},
    {label: "Order DESC", value: `order_no desc`},
    {label: "Order ASC", value: `order_no asc`},
  ];
  const filterOptions = [
    {label: "All", value: "all"},
    {label: "Only booked", value: "booked"},
  ];

  function getStatusWord(status) {
    const statusMapping = {
      'draft': 'Draft',
      'pending_review': 'Pending Review',
      'requested_changes': 'Requested Changes',
      'live': 'Live',
      'updates_pending': 'Updates Pending',
      'canceled': 'Canceled',
      'archived': 'Archived',
    };

    return statusMapping[status] || status;
  }

  function editExperience(data) {
    navigate(`/account/edit-experience/${data.id}`);
  }

  function rejectExperience(experience) {
    // ...
  }
  function acrhiveExperience(experience) {
    // ...
  }
  async function cloneExperience(id) {
    try {
      const resp = await axios.post(`/api/experience/${id}/clone`)
      const createdId = resp.data?.data?.id
      if (createdId) {
        navigate(`/account/edit-experience/${createdId}`);
      } else {
        context.handleError(resp.data?.data?.message);
      }
    } catch (e) {
      context.handleError(e);
    }
  }
  const getActionsForExperience = function (experience) {

    const custom = []
    const actions = {
      custom,
    }

    // can only approve if in pending review and Super admin
    if (experience.status === 'pending_review' && auth.permission.superAdministrator) {
      custom.push({
        action: approveExperience,
        icon: 'star',
        actionName: 'Approve'
      })
    }

    // can clone only if not pending updates or if it's not a child of pending updates (duplicate)
    if (experience.status !== 'updates_pending' && !experience.original_experience_id) {
      custom.push({
        action: () => cloneExperience(experience.id),
        icon: 'copy',
        actionName: 'Copy'
      })
    }

    // If the experience is in updates pending, can only view it unless we are not viewing the dupliates list
    if (experience.status === 'updates_pending' && !props.updatesOnly) {
      custom.push({
        icon: 'eye',
        action: editExperience,
        actionName: 'View'
      })
    }

    // can only edit on the duplicates list page
    if ((experience.status !== 'updates_pending' || props.updatesOnly)) {
      actions.edit = editExperience;
    }

    // can archive if not updates_pending
    if (!['updates_pending', 'archived'].includes(experience.status)) {
      custom.push({
        icon: 'archive',
        action: archiveExperience,
        actionName: 'Archive'
      })
    }

    return actions
  }

  const search = function (_, text) {
    const newFilters = {...filters}
    newFilters.searchText = text
    setFilters(newFilters)
  }

  function sort(_, value) {
    const newFilters = {...filters}
    newFilters.orderBy = value
    setFilters(newFilters)
  }
  function filterChange(_, value) {
    const {booked, ...newFilters} = {...filters}
    if (value === 'booked') {
      newFilters.booked = 1
    }
    setFilters(newFilters)
  }

  function paginate(page) {
    const newFilters = {...filters}
    newFilters.page = page
    setFilters(newFilters)
  }
  function update_experience_order(experienceId, newOrderNo) {
    axios
      .patch(`/api/experience/order-no`, {id: experienceId, order_no: newOrderNo})
      .then(() => {
        setExperiences(e => e.map(i => {
          if (i.id === experienceId) {
            i.experience_order.value = newOrderNo
          }
          return i
        }))
      })
  }
  function approveExperience(experience) {
    // ...
  }

  function archiveExperience(experience) {
    viewContext.modal.show({
      title: `Archive experience ${experience.title}`,
      form: {},
      buttonText: 'Archive',
      url: `/api/experience/${experience.id}/archive`,
      method: 'PATCH',
    }, () => {
      viewContext.notification.show(`${experience.title} was archived`, 'success', true);
      setFilters({...filters})
    });
  }

  useEffect(() => {
    const params = new URLSearchParams({
      searchText: filters.searchText,
      page: filters.page,
      limit: filters.limit,
      orderBy: filters.orderBy,
      updatesOnly: props.updatesOnly ? 1 : 0,
    })
    if (filters.booked !== undefined) {
      params.append('booked', filters.booked)
    }
    axios
      .get(`/api/experience/list?${params.toString()}`)
      .then(data => data.data)
      .then(payload => {
        let list = []
        setTotalCount(payload.count)
        const data = payload.data
        if (data?.length) {
          list = data.map(x => {
            return {
              id: x.id,
              title: x.title,
              date: x.date ? moment(x.date).format('YYYY-MM-DD') : 'Ongoing',
              status: getStatusWord(x.status),
              provider_name: x.user.name,
              featured: x.featured,
              experience_order: {
                value: x.order_no === null ? undefined : x.order_no,
                cb: (order_no) => update_experience_order(x.id, order_no)
              },
              actions: getActionsForExperience(x)
            };
          });
        }
        setExperiences(list);
      })
  }, [filters]);

  return (
    <Fragment>
      <Animate>
        <TitleRow title="Experiences">
          <Button small text="Create Experience" goto="/account/create-experience" />
        </TitleRow>
        <div className='flex items-center mb-2'>
          <div className="w-[300px] mr-2"><Select label="Sort" className="bg-white" options={sortOptions} onChange={sort} /></div>
          <div className="w-[300px] mr-2"><Select label="Show only" className="bg-white" options={filterOptions} onChange={filterChange} /></div>
          <div className="w-[300px]">
            <TextInput label="Search" placeholder="Search" onChange={search} value={filters.searchText} />
          </div>
        </div>
        <Card>
          <Table
            className="restrict-width"
            data={experiences}
            loading={loading}
            actionTitle="Experience"
            show={['title', 'date', 'provider_name', 'status', 'experience_order', 'featured']}
            badge={{
              col: 'status',
              color: 'blue',
              condition: [
                {value: 'Draft', color: 'stone'},
                {value: 'Pending Review', color: 'yellow'},
                {value: 'Requested Changes', color: 'orange'},
                {value: 'Live', color: 'green'},
                {value: 'Updates Pending', color: 'pink'},
                {value: 'Canceled', color: 'red'},
                {value: 'Archived', color: 'stone'},
              ],
            }}
          />
        </Card>
        <Pagination page={filters.page} limit={filters.limit} total={totalCount} onChange={paginate}></Pagination>
      </Animate>
    </Fragment>
  );
}
