/***
*
*   RESET PASSWORD
*   User can set a new password using the token
*
**********/

import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import { Animate, Row, AuthContext, Card, Form, useNavigate, useLocation } from 'components/lib';

export function ResetPassword(props){
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [email, setEmail] = useState('');
  const qs = location.search;
  const token = qs.substring(qs.indexOf('?token=') + 7);
  useEffect(() => {
    setLoading(true);
    const res = Axios.post('api/auth/password/reset/verify', {token})
      .then(res => {
        if (res.data?.email && res.data?.email.indexOf('@') > 1) setEmail(res.data.email);
        else setError(true);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
  }, []);

  return(
    <Animate type='pop'>
      <Row title='Reset Your Password' color='black'>
        <Card restrictWidth center>
          { isLoading
          ? <h3 style={{textAlign:'center'}}>Validating your password reset link...</h3>
          : ( isError
            ? <h3 style={{textAlign:'center',color:'#F03313'}}>Password reset link is not valid</h3>
            : <Form
                inputs={{
                    jwt: {
                        type: 'hidden',
                        value: token,
                    },
                    email: {
                        label: 'Email',
                        type: 'hidden',
                        value: email,
                    },
                    password: {
                        label: 'New Password',
                        type: 'password',
                        required: true,
                        complexPassword: true,
                    },
                    passwordConfirm: {
                        label: 'Confirm New Password',
                        type: 'password',
                        required: true,
                        complexPassword: true,
                    }
                }}
                url='/api/auth/password/reset'
                method='POST'
                buttonText='Set New Password'
                buttonColor='black'
                callback={ res => {
                    res.data['2fa_required'] ? navigate(`/signin/otp?token=${res.data.token}`) : context.signin(res);
                }}
            />
          )}
        </Card>
      </Row>
    </Animate>
  );
}
