/***
*
*   SIGN UP STEP 2
*   Verify email address
*   Step 1: create account
*   Step 2: verify email address
*   Step 3: select plan
*
**********/

import React, {useContext, useState, useEffect, useRef} from 'react';
import Axios from 'axios';
import {AuthContext, Animate, Row, Card, Message, Link, useLocation, useNavigate} from 'components/lib';
import {useAuth0} from '@auth0/auth0-react';

function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function SignupVerification(props) {

  const location = useLocation();
  const navigate = useNavigate();
  const qs = location.search;
  const authContext = useRef(useContext(AuthContext));
  const {isAuthenticated} = useAuth0()

  const [message, setMessage] = useState({

    type: 'info',
    title: 'Please Check Your Email',
    text: 'Please click the link in the email we sent to verify your account.',
    button: {
      text: 'Re-Send Verification Email',
      action: resendVerificationEmail
    }
  });

  useEffect(() => {
    const verifyToken = async (token) => {

      try {

        setMessage({

          type: 'success',
          title: 'Verifying...',
          text: 'Please wait a moment while we verify your email.',
          button: false,

        })

        await Axios.post('/api/user/verify', {token: token});
        authContext.current.update({verified: true});
        await wait(1000);
        window.location.href = '/dashboard';
      }
      catch (err) {

        // // token isnt for this account, force signout
        if (err.response?.status === 401)
          return authContext.current.signout();

        setMessage({

          type: 'error',
          title: 'Verification Link Has Expired',
          text: 'Please re-send the verification email and try again',
          button: {
            text: 'Re-Send Verification Email',
            action: resendVerificationEmail
          }
        });
      }
    }

    if (qs.includes('?token=')) {

      // check token exists
      verifyToken(qs.substring(qs.indexOf('?token=') + 7));

    }
  }, [qs, authContext, navigate]);

  async function resendVerificationEmail() {
    const emailVerificationToken = localStorage.getItem('emailVerificationToken');
    if (!emailVerificationToken) {
      // handle this
    }

    setMessage({

      type: 'success',
      title: 'Please Check Your Email',
      text: 'Please click the link in the email we sent to verify your account.',

    })

    await Axios({method: 'post', url: '/api/user/verify/request', data: {emailVerificationToken: emailVerificationToken}});

  }

  return (
    <Animate type='pop'>
      <Row title='Verify Your Email Address'>
        <Card loading={false} restrictWidth center>

          <Message
            type={message.type}
            title={message.title}
            text={message.text}
            buttonText={message.button?.text}
            buttonAction={message.button?.action}
          />

        </Card>
      </Row>
    </Animate>
  );
}
