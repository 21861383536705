import { Fragment, useState, useEffect } from 'react';
import { Loader, Search, ClassHelper, Checkbox } from 'components/lib';
import { Header } from './header';
import { Body } from './body';
import { BulkActions } from './actions';
import Style from './table.tailwind.js';

export function Table(props){
  // state
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [filter, setFilter] = useState(false);
  const [selected, setSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0)

  const [filters, setFilters] = useState({
    searchText: '',
    page: 1,
    limit: 10,
    orderBy: 'title desc',
  });

  const sortOptions = [
    { label: "Title DESC", value: "title desc" },
    { label: "Title ASC", value: "title asc" },
    { label: "Date DESC", value: "date desc" },
    { label: "Date ASC", value: "date asc" },
    { label: "Status DESC", value: "status desc" },
    { label: "Status ASC", value: "status asc" },
    { label: "Provider Name DESC", value: `"user.name" desc` },
    { label: "Provider Name ASC", value: `"user.name" asc` },
  ];

  useEffect(() => {
    if (props.data){

      // create the headers
      let header = props.data.header || [];

      if (!header.length){
        for (let key in props.data[0]){
          if (key !== 'actions')
          {
            header.push({

              name: key,
              title: key.replace('_', ' '),
              sort: props.disableSort ? false : (key === 'actions' ? false : true)
  
            });
          }
          
        }
      }
      setBody(props.data);
      setHeader(header);

    }
  }, [props.data]);

  // loading
  if (props.loading){
    return (
      <div className={ Style.loading }>
        <Loader />
      </div>
    );
  }

  // no data
  if (!header && !body)
    return false

  function sort(column, direction){

    const rows = filter.length ? [...filter] : [...body];

    rows.sort(function(a,b){

      if ((a[column] != null) && (b[column] != null)){

        a[column].badge ?
          a = a[column].label : a = a[column];

        b[column].badge ?
          b = b[column].label : b = b[column];

        if (direction === 'desc'){

          if (a > b) return -1;
          if (a < b) return 1;
          else return 0;

        }
        else {

          if (a < b) return -1;
          if (a > b) return 1;
          else return 0;

        }
      }
      else {

        return false;

      }
    });

    filter ? setFilter(rows) : setBody(rows);

  }

  function search(term){

    // search each cell in each row &
    // update state to show only filtered rows
    let rowsToShow = [];

    body.forEach(row => {
      for (let cell in row){
        if (row[cell]?.toString().toLowerCase().includes(term.toLowerCase())){

          if (!rowsToShow.includes(row))
            rowsToShow.push(row);

        }
      }
    })

    setFilter(rowsToShow);

  }

  function select(index, id){

    // toggle the select state 
    // save the index of selected
    const s = [...selected];
    const i = s.findIndex(x => x.index === index);
    i > -1 ? s.splice(i, 1) : s.push({ index, id });
    return setSelected(s);

  }

  function selectAll(){

    // toggle all visible rows
    setSelected(selected.length ? [] : 
      props.data.map((x, i) => { return { index: i, id: x.id }}));
    
  }

  function editRowCallback(res, row){

    let state = [...body];
    let stateRow = state[state.findIndex(x => x.id === row.id)];
    Object.keys(res).map(key => stateRow[key] = res[key].value);
    setBody(state);

  }

  function deleteRowCallback(row){

    const b = [...body];
    const remove = Array.isArray(row) ? row : [row]; // ensure array
    // remove from body
    remove.forEach(r => {
      //change status to "disable"
      const index = b.findIndex(x => x.id === r.id);
      if (b[index].status === 'Invited') {
        //delete from table
        b.splice(b.findIndex(x => x.id === r.id), 1);
      } else {
        b[index].status = "disabled";
        b[index].disabled = true;
      }

    });

    setBody(b);
    setSelected([]); // reset selected items to 0

  }

  function markAsDisabledCallback(row) {
    const b = [...body];

    b.forEach((r) => {
      if (Array.isArray(row)) {
        if (row.findIndex((x) => x.id === r.id) > -1) r.disabled = true;
      } else {
        if (r.id === row.id) {
          r.disabled = true;
        }
      }
    });

    setBody(b);
    setSelected([]);
  }

  function markAsEnabledCallback(row) {
    const b = [...body];

    b.forEach((r) => {
      if (Array.isArray(row)) {
        if (row.findIndex((x) => x.id === r.id) > -1) r.disabled = false;
      } else {
        if (r.id === row.id) {
          r.disabled = false;
        }
      }
    });

    setBody(b);
    setSelected([]);
  }

  const tableStyle = ClassHelper(Style, {

    table: true,

  });

  function paginate(page) {
    const newFilters = {...filters}
    newFilters.page = page
    setFilters(newFilters)
  }

  const showSelectable = props.selectable && props.bulkActions && Object.keys(props.bulkActions).length && selected.length;

  return (
    <Fragment>
      { props.search || showSelectable ?
        <div className={ Style.inputs }>

          { props.search &&
            <Search className={ Style.search } callback={ search } throttle={ props.throttle }/> }
            {
              props.sortable &&
              <div className='ml-2 flex items-center justify-center'>
                <b className='mr-2'>Sort by:</b>
                <select
                  value={(props?.orderBy)}
                  onChange={(e) => props?.setOrderBy(e.target.value)}
                >
                  <option value={null}>Select an option</option>
                  {
                    sortOptions.map((el, i) => (
                      <option key={i} value={el.value}>{el.label}</option>
                    ))
                  }
                </select>
              </div>
            }
            
          { showSelectable ?
            <BulkActions 
              actions={ props.bulkActions } 
              selected={ selected }
              delete={ deleteRowCallback }
            /> : undefined }

        </div> : undefined 
      }
      { /* select all for mobile */ }
      { showSelectable ?
        <div className={ Style.select_all }>
          <Checkbox 
            option='Select all' 
            callback={ selectAll }
            checked={ selected.length === props.data?.length }
          /> 
        </div> : undefined
      }
        
      <table className={ !props.naked && tableStyle }>

        { header &&
          <Header
            data={ header }
            callback={ sort }
            show={ props.show }
            hide={ props.hide }
            select={ props.selectable ? selectAll : false }
            hasData={ props.data?.length }
            selectAll={ selected.length === props.data?.length }
            actions={ props.actions }
          />
        }
        { body &&
          <Body
            data={ filter ? filter : body }
            show={ props.show }
            hide={ props.hide }
            badge={ props.badge }
            valuesMap={ props.valuesMap }
            select={ props.selectable ? select : false }
            selected={ selected }
            actionTitle={ props.actionTitle }
            actions={{

              edit: props.actions?.edit,
              view: props.actions?.view,
              delete: props.actions?.delete,
              markAsDisabled: props.actions?.markAsDisabled,
              markAsEnabled: props.actions?.markAsEnabled,
              email: props.actions?.email,
              custom: props.actions?.custom,

            }}
            callback={{

              edit: editRowCallback,
              delete: deleteRowCallback,
              markAsDisabled: markAsDisabledCallback,
              markAsEnabled: markAsEnabledCallback,

            }}
          />
        }
      </table>
    </Fragment>
  );
}