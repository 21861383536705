/* eslint-disable no-async-without-await/no-async-without-await */

import axios from 'axios';
import {AccountNav, Animate, AuthContext, Button, Card, Table, TextInput, TitleRow, ViewContext, useAPI} from 'components/lib';
import {Fragment, useEffect, useState} from 'react';
import {Pagination} from 'components/pagination/pagination';
import {useContext} from 'react';
import {useCallback} from 'react';
import {status} from 'components/users/usersFunctions';

export function Users() {
  const cache = JSON.parse(localStorage.getItem('user'));
  const userAPI = useAPI(cache ? null : `/api/user`);
  const currentUser = cache ? cache : userAPI;
  const context = useContext(ViewContext);

  let [searchText, setSearchText] = useState('')

  let [userLoading, setUserLoading] = useState(false)
  let [inviteLoading, setInviteLoading] = useState(false)

  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);

  const permissionValueMap = {
    'superAdministrator': 'VIPSRV Super Administrator',
    'administrator': 'Provider Administrator',
    'provider': 'Provider',
    'concierge': 'Concierge',
  };

  const [users, setUsers] = useState([]);
  const [invites, setInvites] = useState([]);
  const [userTotalCount, setUserTotalCount] = useState(0)
  const [inviteTotalCount, setInviteTotalCount] = useState(0)
  const [userFilters, setUserFilters] = useState({
    searchText: '',
    page: 1,
    limit: 5,
  });
  const [inviteFilters, setInviteFilters] = useState({
    searchText: '',
    page: 1,
    limit: 5,
  });
  const getUserStatus = (user) => {
    if (user.reinvited) return 'invited';
    if (user.disabled) return 'disabled';
    if (user.verified) return 'verified';
    return 'registered';
  }

  function editUser(data, callback) {
    context.modal.show({
      title: 'Update User',
      form: {
        email: {
          label: 'Email',
          type: 'email',
          value: data.email,
          required: true,
        },
        name: {
          label: 'First Name',
          type: 'text',
          required: true,
          value: data.name,
          errorMessage: 'Please enter a name'
        },
        last_name: {
          label: 'Last Name',
          type: 'text',
          required: true,
          value: data.last_name,
          errorMessage: 'Please enter a last name'
        },
      },
      buttonText: 'Save',
      url: `/api/user/${data.id}`,
      method: 'PATCH'

    }, (res) => {

      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);
    });

  }

  function deleteUser(data, callback) {

    context.modal.show({
      title: 'Disable User',
      form: {},
      buttonText: 'Disable User',
      text: `Are you sure you want to disable ${data.name}?`,
      url: `/api/user/disabled/${data.id}`,
      method: 'DELETE',
      destructive: true

    }, () => {

      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();
      setUserFilters(f => ({...f}))
    });
  }
  async function undeleteUser(data, callback) {
    let isError = false;
    try {
      await axios.post(`api/user/enabled/${data.id}`);
    } catch (err) {
      isError = true;
      context.handleError(err);
    } finally {
      if (!isError) {
        setUserFilters(f => ({...f}))
      }
    }
  }
  function invite() {

    context.modal.show({
      title: 'Invite User',
      form: {
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        },
        first_name: {
          label: 'First Name',
          type: 'text',
          required: true,
        },
        last_name: {
          label: 'Last Name',
          type: 'text',
          required: true,
        }
      },
      buttonText: 'Send Invite',
      // text: 'To invite more than one user, separate the emails with a comma',
      url: '/api/invite/provider',
      method: 'POST',

    }, (form, res) => {
      setUserFilters(f => ({...f}))
    });
  }
  const deleteInvite = useCallback((data, callback) => {
    const id = data.reinvite_id ? data.reinvite_id : data.id;
    context.modal.show({
      title: 'Delete Invite',
      form: {},
      buttonText: 'Delete Invite',
      text: `Are you sure you want to delete the invite for ${data.email}?`,
      url: `/api/invite/${id}`,
      method: 'DELETE',

    }, () => {

      callback();
      context.notification.show(`${data.email}'s invite was deleted`, 'success', true);
    });
  }, [context.modal, context.notification]);
  async function resendInvite(data) {
    try {
      const response = await axios({

        url: '/api/invite/provider',
        method: 'post',
        data: {email: data.email, first_name: data.name, last_name: data.last_name, reinvite: true}

      });
      if (response.status === 200) {
        context.notification.show(`Invite re-sent to ${data.email}`, 'success', true);
        setUsers((prev) => {
          const state = [...prev];
          const index = state.findIndex(({id}) => id === data.id);
          if (index >= 0 && state?.[index]?.disabled) {
            state[index].reinvited = true;
            state[index].status = "reinvited";
            state[index].actions = {
              edit: editUser,
              invite: resendInvite,
              delete: deleteInvite
            }
          }
          return state
        });
      }

    }
    catch (err) {

      context.handleError(err);

    }
  }

  function getActions(u) {
    let actions
    if (u.status === 'Invited') {
      actions = {
        invite: resendInvite,
        delete: deleteInvite
      }
    }
    else if (u.disabled && u.reinvited) {
      actions = {
        edit: editUser,
        invite: resendInvite,
        delete: deleteInvite
      }
    }
    else if (u.disabled) {
      actions = {
        edit: editUser,
        markAsEnabled: undeleteUser,
        invite: resendInvite,
      }
    }
    else if (u.reinvited) {
      actions = {
        edit: editUser,
        invite: resendInvite,
        delete: deleteInvite
      }
    }
    else if (u.id === currentUser?.data?.id) {
      actions = {}
    }
    return actions
  }

  function paginateUsers(page) {
    const newFilters = {...userFilters}
    newFilters.page = page
    setUserFilters(newFilters)
  }
  function paginateInvites(page) {
    const newFilters = {...inviteFilters}
    newFilters.page = page
    setInviteFilters(newFilters)
  }
  const search = function (_, text) {
    setSearchText(text)

    const newInviteFilters = {...inviteFilters}
    newInviteFilters.searchText = text
    setInviteFilters(newInviteFilters)

    const newUserFilters = {...userFilters}
    newUserFilters.searchText = text
    setUserFilters(newUserFilters)
  }

  useEffect(() => {
    setUserLoading(true)
    const params = new URLSearchParams({
      page: userFilters.page,
      limit: userFilters.limit,
      searchText: userFilters.searchText,
    })

    axios
      .get(`/api/organization/auditory/users?${params.toString()}`)
      .then(data => data.data.data)
      .then(payload => {
        let list = []
        setUserTotalCount(payload.totalCount)
        if (payload.items?.length) {
          list = payload.items.map(x => {
            const mapped = {
              id: x.id,
              avatar: x.avatar,
              name: x.name,
              last_name: x.last_name,
              email: x.email,
              disabled: x.disabled,
              reinvited: x.reinvited,
              reinvite_id: x.reinvite_id,
              date_created: x.date_created,
              permission: x.permission,
              status: status(x),
              actions: getActions(x)
            }
            mapped.actions = getActions(mapped)

            return mapped;
          });
        }
        setUsers(list);
        setUserLoading(false)
      })
  }, [userFilters]);

  useEffect(() => {
    setInviteLoading(true)
    const params = new URLSearchParams({
      // searchText: userFilters.searchText,
      page: inviteFilters.page,
      limit: inviteFilters.limit,
      searchText: inviteFilters.searchText,
    })

    axios
      .get(`/api/organization/auditory/invites?${params.toString()}`)
      .then(data => data.data.data)
      .then(payload => {
        let list = []
        setInviteTotalCount(payload.totalCount)
        if (payload.items?.length) {
          list = payload.items.map(x => {
           
            const mapped =
            {
              id: x.id,
              name: x.name,
              last_name: x.last_name,
              email: x.email,
              date_created: x.date_sent,
              permission: x.permission || 'provider',
              status: 'Invited',
            }
            mapped.actions = getActions(mapped)

            return mapped;
          });
        }
        setInvites(list);
        setInviteLoading(false)
      })
  }, [inviteFilters]);
  return (
    <Fragment>

      <AccountNav />
      <Animate>
        <div class="flex justify-between items-end mb-2">
          <div className="w-[300px] mr-2">
            <TextInput label="Search" placeholder="Search" onChange={search} value={searchText} />
          </div>
          <div className="mr-2">
            <Button small text='Add User' action={invite} />
          </div>
        </div>
        <div>
          <TitleRow title='Manage Users'>
          </TitleRow>
          <Card>
            <Table
              className='restrict-width'
              actionTitle='User'
              data={users}
              valuesMap={{permission: permissionValueMap}}
              loading={userLoading}
              show={['email', 'name', 'last_name', 'date_created', 'last_login', 'permission', 'status']}
              badge={{
                col: 'status', color: 'blue', condition: [
                  {value: 'verified', color: 'green'},
                  {value: 'registered', color: 'blue'},
                  {value: 'invited', color: 'orange'},
                  {value: 'disabled', color: 'red'}
                ]
              }}
              actions={{
                edit: editUser,
                markAsDisabled: deleteUser,
                invite: resendInvite,
              }}
            />
          </Card>
          <Pagination page={userFilters.page} limit={userFilters.limit} total={userTotalCount} onChange={paginateUsers}></Pagination>

        </div>
        <div>
          <TitleRow title='Manage Invites'>
          </TitleRow>
          <Card>
            <Table
              className='restrict-width'
              actionTitle='User'
              data={invites}
              valuesMap={{permission: permissionValueMap}}
              loading={inviteLoading}
              show={['email', 'name', 'last_name', 'date_created', 'last_login', 'permission', 'status']}
              badge={{
                col: 'status', color: 'blue', condition: [
                  {value: 'verified', color: 'green'},
                  {value: 'registered', color: 'blue'},
                  {value: 'invited', color: 'orange'},
                  {value: 'disabled', color: 'red'}
                ]
              }}
              actions={{
                edit: editUser,
                markAsDisabled: deleteUser,
                invite: resendInvite,
              }}
            />
          </Card>
          <Pagination page={inviteFilters.page} limit={inviteFilters.limit} total={inviteTotalCount} onChange={paginateInvites}></Pagination>

        </div>
      </Animate>
    </Fragment>
  );
}
