// tailwind css
import '../css/output.css';

import Axios from 'axios';

// components
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {PrivateRoute, AuthProvider} from './auth';
import {View} from 'components/lib';
// import { loadStripe } from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

// 404
import {NotFound} from 'views/error/404';
import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';
import {useState} from 'react';

// settings
const Settings = require('settings.json');

if (process.env.REACT_APP_SERVER_URL) Settings[process.env.NODE_ENV].server_url = process.env.REACT_APP_SERVER_URL;
//const StripePromise = loadStripe(Settings[process.env.NODE_ENV].stripe.publishableAPIKey);

const routes = [

  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,

]

export default function App(props) {
  const {isAuthenticated, getAccessTokenSilently, isLoading: isAuth0Loading} = useAuth0();
  const [token, setToken] = useState(null)
  const [user, setUser] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;

  useEffect(() => {
    // eslint-disable-next-line no-floating-promise/no-floating-promise
    (async () => {
      if (!isAuth0Loading && isAuthenticated) {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_TOKEN_SCOPE,
          },
        });

        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        setToken(token)

        const user = JSON.parse(localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined' ? localStorage.getItem('user') : null);

        setUser(user)
        setIsLoading(false)

      } else if (!isAuth0Loading) {
        setIsLoading(false)
      }

    })();

  }, [isAuthenticated, getAccessTokenSilently, isAuth0Loading])

  if (isLoading) return <></>

  // render the routes
  return (
    <Elements stripe={
      null
      //StripePromise
    }>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            {routes.map(route => {

              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={

                    route.permission ?
                      <PrivateRoute permission={route.permission} user={user} token={token}>
                        <View display={route.view} layout={route.layout} title={route.title} />
                      </PrivateRoute> :
                      <View display={route.view} layout={route.layout} title={route.title} />

                  }
                />
              )
            })}

            { /* 404 */}
            <Route path='*' element={<View display={NotFound} layout='home' title='404 Not Found' />} />

          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Elements>
  );
}
