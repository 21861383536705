
import axios from 'axios';
import {Animate, Card, DateInput, Select, Table, TextInput, TitleRow} from 'components/lib';
import moment from 'moment';
import {Fragment, useEffect, useState} from 'react';
import {Pagination} from 'components/pagination/pagination';

export function Bookings(props) {
  const [loading, setLoading] = useState(false)
  const [bookings, setBookings] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [filters, setFilters] = useState({
    searchText: '',
    startDate: undefined,
    endDate: undefined,
    page: 1,
    limit: 10,
    orderBy: '"experience.title" desc',
  });

  const sortOptions = [
    {label: "Title DESC", value: '"experience.title" desc'},
    {label: "Title ASC", value: '"experience.title" asc'},
    {label: "Event Date DESC", value: '"experience.date" desc'},
    {label: "Event ASC", value: '"experience.date" asc'},
    {label: "Option Title DESC", value: '"experience_options.title" desc'},
    {label: "Option Title ASC", value: '"experience_options.title" asc'},
    {label: "Quantity DESC", value: '"quantity" desc'},
    {label: "Quantity ASC", value: '"quantity" asc'},
    {label: "Total Amount DESC", value: '"amount" desc'},
    {label: "Total Amount ASC", value: '"amount" asc'},
    {label: "Created At DESC", value: '"created_at" desc'},
    {label: "Created At ASC", value: '"created_at" asc'},
    {label: "Client DESC", value:  '"client_name" desc'},
    {label: "Client ASC", value:   '"client_name" asc'},
    {label: "Concierge DESC", value:  '"concierge.full_name" desc'},
    {label: "Concierge ASC", value:  '"concierge.full_name" asc'},
  ];

  function isoTimestampToDateMidnight(isoTimestamp) {
    const date = new Date(isoTimestamp);
    date.setUTCHours(0, 0, 0, 0); 
    return date;
  }
  function getTimeStatus(booking) {

    if (!booking.experience.date) {
      return 'Ongoing';
    }

    const bookingDate = isoTimestampToDateMidnight(booking.experience.date)
    const now = isoTimestampToDateMidnight(new Date().toISOString())
   

    if (bookingDate.getTime() > now.getTime()) {
      return 'Upcoming';
    }

    return 'Past';
  }
  const search = function (_, text) {
    const newFilters = {...filters}
    newFilters.searchText = text
    setFilters(newFilters)
  }

  function sort(_, value) {
    const newFilters = {...filters}
    newFilters.orderBy = value
    setFilters(newFilters)
  }

  function startDateChange(_, value) {
    const newFilters = {...filters}
    newFilters.startDate = value
    setFilters(newFilters)
  }
  function endDateChange(_, value) {
    const newFilters = {...filters}
    newFilters.endDate = value
    setFilters(newFilters)
  }
  function paginate(page) {
    const newFilters = {...filters}
    newFilters.page = page
    setFilters(newFilters)
  }


  useEffect(() => {
    const params = new URLSearchParams({
      searchText: filters.searchText,
      page: filters.page,
      limit: filters.limit,
      orderBy: filters.orderBy,
      ...(filters.startDate ? {startDate: filters.startDate} : {}),
      ...(filters.endDate ? {endDate: filters.endDate} : {}),
    })

    axios
      .get(`/api/booking?${params.toString()}`)
      .then(data => data.data)
      .then(payload => {
        let list = []
        setTotalCount(payload.totalCount)
        const data = payload.items
        if (data?.length) {
          list = data.map(x => {
            return {
              id: x.id,
              title: x.experience.title,
              date: x.experience.date ? moment(x.experience.date).utc().format('YYYY-MM-DD') : 'Ongoing',
              status: getTimeStatus(x),
              'option title': x.option.title,
              quantity: x.quantity,
              'total price': x.amount,
              concierge: `${x.concierge.name} ${x.concierge.last_name}\n ${x.concierge.email}`,
              client: `${x.client_name || ''}\n ${x.client_email || ''}`,
              'reference number': x?.ref_id || '',
              created: moment(x.created_at).utc().format('YYYY-MM-DD HH:mm:ss'),
            };
          });
        }
        setBookings(list);
      })
  }, [filters]);

  return (
    <Fragment>
      <Animate>
        <TitleRow title="Booked Experiences">
        </TitleRow>
        <div className='flex items-center mb-2'>
          <div className="w-[300px] mr-2"><Select label="Sort" className="bg-white" options={sortOptions} onChange={sort} /></div>
          <div className="w-[300px] mr-2">
            <TextInput label="Search" placeholder="Search" onChange={search} value={filters.searchText} />
          </div>
          <div className="w-[300px] mr-2">
            <DateInput label="Start Date" value={filters.startDate} onChange={startDateChange} />
          </div>
          <div className="w-[300px] mr-2">
            <DateInput label="End Date" value={filters.endDate} onChange={endDateChange} />
          </div>
   
        </div>
        <Card>
          <Table
            className="restrict-width"
            actionTitle='Booking'
            data={bookings}
            loading={loading}
            show={[
              'title',
              'date',
              'status',
              'option title',
              'quantity',
              'total price',
              'concierge',
              'client',
              'created',
              'reference number'
            ]}
          />
        </Card>
        <Pagination page={filters.page} limit={filters.limit} total={totalCount} onChange={paginate}></Pagination>
      </Animate>
    </Fragment>
  );
}
