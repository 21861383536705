/***
*
*   HOME NAV
*   Navigation used on the main external website. Renders a dashboard link
*   if the user is signed in, or a sign up link if they are not
*
**********/

import { Fragment, useContext } from 'react';
import { AuthContext, Logo, Button, Content, ClassHelper, useNavigate } from 'components/lib';
import Style from './home.tailwind.js';

export function HomeNav(props){
  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {

    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent

  });
  return(
    <section className={ css }>
      <Content>

        <Logo className={ Style.logo }/>
        <nav className={ Style.nav }>

          { !(context.user?.token) &&
            <Fragment>
              <a className='inline-block' style={{ color: 'white' }} href={process.env.REACT_APP_FE_URL || '/'}>Go to VIPSRV</a> 
              {/* <Button color='white' small goto='/signin' text='SIGN IN' className='inline-block' /> */}
            </Fragment>
          }
        </nav>
      </Content>
    </section>
  );
}