import { Dashboard } from 'views/dashboard/dashboard';
import { Terms } from 'views/terms-and-conditions/terms';

const Routes = [
  {
    path: '/',
    view: Dashboard,
    layout: 'app',
    permission: 'provider',
    title: 'Dashboard'
  },
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'provider',
    title: 'Dashboard'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'auth',
    title: 'Terms & Conditions'
  },
]

export default Routes;
