import React, {useCallback} from 'react';
import {TextInput, NumberInput, Button, HiddenInput} from 'components/lib';
import {Tip} from '../tip/tip';

export const Options = ({optionsData, optionsValuesArr, update, setForm,priceUponRequest}) => {
  const Inputs = {
    text: TextInput,
    textarea: TextInput,
    number: NumberInput,
    hidden: HiddenInput
  }

  const addOptionAction = useCallback(() => {
    if (optionsValuesArr.length < optionsData.max) {
      setForm((prev) => {
      const maxSortOrder = Math.max(...prev.options.values.map(option => option.sort_order.value))
        const newForm = structuredClone(prev);
        newForm.options.values.push({
          "title": {
            "label": "Title",
            "type": "text",
            "required": true,
            "options": "options",
            "max": 65,
          },
          "description": {
            "label": "Description",
            "type": "textarea",
            "required": true,
            "max": 1500,
          },
          "price": {
            "label": "Option Price",
            "type": "number",
            "tip":"Please input the Price (USD) for this Option or select Pricing Available Upon Request.",	
            "required": !priceUponRequest,
            "disabled": !!priceUponRequest,
          },
          "quantity": {
            "label": "Option Quantity available",
            "type": "number",
            "required": true
          },
          "sort_order": {
            "type": "hidden",
            "value": maxSortOrder + 1
          }
        });
        return newForm;
      })
    }
  }, [optionsValuesArr, setForm, optionsData.max, priceUponRequest]);


  const removeOptionAction = useCallback(
    idx => {
      if (optionsValuesArr.length > 1) {
        setForm(prev => {
          const newForm = {...prev};
          newForm.options.values.splice(idx, 1);
          return newForm;
        });
      }
    },
    [optionsValuesArr, setForm],
  );

  return (
    <div className="pb-5">
      <h2 className="uppercase py-3">{optionsData.label}</h2>
      <Tip text="Options: Add up to three options for an Experience. Examples: VIP Stage Access Day 1, VIP Stage Access Day 2" />
      <Button
        className="mb-3"
        color={optionsValuesArr.length < optionsData.max ? 'green' : 'gray'}
        text="Add option"
        action={addOptionAction}
      />
      <div>
        <div className="flex gap-x-3">
          {optionsValuesArr.map((option, valuesIdx) => (
            <div key={option + valuesIdx} className="border-slate-500 w-[25%]">
              <h3 className="uppercase py-3">{`Option ${valuesIdx + 1}`}</h3>
              <div>
                {option.map((input, idx) => {
                  if (input.type === null)
                    return false;

                  if (!input.type)
                    input.type = 'text';
                  if (input.type === 'options')
                    return false;

                  const Input = Inputs[input.type];

                  return (
                    <Input
                      key={input.name + idx}
                      type={input.type}
                      label={input.label}
                      className={input.class}
                      tip={input.tip}
                      name={input.name}
                      value={input.value}
                      required={input.required}
                      valid={input.valid}
                      min={input.min}
                      max={input.max}
                      disabled={input.disabled}
                      options={input.options}
                      default={input.default}
                      url={input.url}
                      text={input.text}
                      title={input.title}
                      accept={input.accept}
                      description={input.description}
                      readonly={input.readonly}
                      handleLabel={input.handleLabel}
                      placeholder={input.placeholder}
                      errorMessage={input.errorMessage}
                      onChange={update}
                      idx={valuesIdx}
                    />
                  );
                })}
                {optionsValuesArr.length > 1 && (
                  <Button
                    color={optionsValuesArr.length === 1 ? 'gray' : 'red'}
                    text="Delete option"
                    action={() => removeOptionAction(valuesIdx)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
